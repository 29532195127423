export const en = {
    iNaturalist: 'iNaturalist',
    iNaturalistWeb: 'iNaturalist.org',
    iNaturalistAPI: 'iNaturalist API',
    myWild: 'MyWild',
    headerTitle: 'Wild Achievements',
    headerIntro1: 'Wild Achievements enhances your iNaturalist experience!',
    headerIntro2: 'Various fun achievements can be unlocked by analyzing your observations on iNaturalist.',
    headerIntroINatStart: 'Upload more observations on ',
    headerIntroINatEnd: ' to try and unlock all achievements!',
    headerCalculate: 'Calculate Achievements',
    headerLoading: 'Loading Achievements',
    headerInput: 'iNaturalist Username',
    version: 'Version: {{version}} | Last Modified: {{timestamp}} | Code: {{commit}}',
    menuPopupParamsTitle: 'Available URL Parameters',
    menuPopupParamsUser: 'Immediately load the achievements for the specified user.',
    menuPopupParamsLimit: 'The maximum number of observations that should be fetched from iNaturalist (rounded up to the next iteration of 200). Mostly for use during development.',
    menuPopupParamsExample: 'Example:',
    menuPopupAboutTitle: 'About Wild Achievements',
    menuPopupAboutMyWildHeading: 'Developed by MyWild (Henry de Lange).',
    menuPopupAboutMyWildTextStart: 'For any feedback you are welcome to log an issue on GitHub, or email me directly at ',
    menuPopupAboutMyWildTextEnd: '.',
    menuPopupAboutMyWildDisclaimer: 'Note that this is not an officially iNaturalist website.',
    menuPopupAboutINaturalistTextStart: 'This website is powered by the ',
    menuPopupAboutINaturalistTextEnd: '.',
    menuPopupRulesTitle: 'How does it work?',
    menuPopupRules: 'Observations of captive, casual and non-verifiable observations are excluded. This website loads data via the iNaturalist API and strives to keep to the usage limits defined by iNaturalist. As a result the loading process can take a couple of minutes for users with many observations. Some data will be cached locally to speed up future processing.',
    menuButtonAbout: 'About',
    menuButtonRules: 'Rules',
    menuButtonParams: 'URL',
    menuButtonGitHub: 'GitHub',
    menuButtonDesktopApp: 'App',
    menuButtonDarkTheme: 'Dark Theme',
    menuButtonLightTheme: 'Light Theme',
    menuButtonSave: 'Save as an Image',
    achievementTotal: 'You\'ve completed {{count}} achievements!',
    cardCountOfGoal: '{{count}} of {{goal}}',
    cardCompletedPercentage: 'Completed: {{percentage}}%',
    cardMysteryTitle: 'It\'s still a mystery...',
    cardMysteryDetails: 'Keep making observations to learn more about this achievement!',
    cardLegend: 'Legend',
    cardMaster: 'Master',
    cardExpert: 'Expert',
    cardProficient: 'Proficient',
    cardCompetent: 'Competent',
    cardNovice: 'Novice',
    cardCasual: 'Casual',
    cardStarted: 'Started',
    cardViewObservations: 'View Observations:',
    cardNoObservations: 'No Observations',
    progressUnknown: 'unknown',
    progressSleep: 'Giving the iNaturalist server a brief rest...',
    progressFetching: 'Fetching the next {{per_page}} observations from iNaturalist... ({{count}} of {{total}} fetched so far)',
    progressPreparing: 'Additional taxon related data is being fetched from iNaturalist, this might take a while... (The results will be locally cached to speed up future requests.)',
    progressCalculating: 'Calculating the Wild Achievements from the {{per_page}} new observations...',
    progressDone: 'The Wild Achievements for {{user}} have been calculated, using the {{count}} most recent observations from iNaturalist!',
    achievementLifeListerTitle: 'Life Lister',
    achievementLifeListerDetails: 'Obtain {{goal}} observations with different species level (or lower) identifications in one year.',
    achievementSelfPollinatorTitle: 'Self Pollinator',
    achievementSelfPollinatorDetails: 'Add comments on {{goal}} of your own observations.',
    achievementTryMammalsTitle: 'Mammal Tryhard',
    achievementTryMammalsDetails: 'Obtain {{goal}} observations of flying, swimming and digging mammals (at least 3 of each).',
    achievementNightOwlTitle: 'Night Owl',
    achievementNightOwlDetails: 'Obtain {{goal}} Owl or Nightjar observations at night (after 6PM and before 6AM).',
    achievementKingFisherTitle: 'King Fisher',
    achievementKingFisherDetails: 'Obtain {{goal}} Fish observations.',
    achievementDaisyTownTitle: 'Daisy Town',
    achievementDaisyTownDetails: 'Obtain {{goal}} Daisy observations.',
    achievementHeartOfTheMatterTitle: 'Heart Of The Matter',
    achievementHeartOfTheMatterDetails: 'Obtain {{goal}} observations of threatened species.',
    achievementSocialButterflyTitle: 'Social Butterfly',
    achievementSocialButterflyDetails: 'Obtain {{goal}} observations of Lepidoptera (Butterflies and Moths), with comments added to the observation.',
    achievementAirLoversTitle: 'Air Lovers',
    achievementAirLoversDetails: 'Obtain {{goal}} observations of Swifts, Swallows, Albatrosses or Vultures.',
    achievementNameGiverTitle: 'Name Giver',
    achievementNameGiverDetails: 'Contribute {{goal}} identifications on observations.',
    achievementRatKingTitle: 'Rat King',
    achievementRatKingDetails: 'Obtain {{goal}} observations of Rodents.',
    achievementCraneyStorkerTitle: 'Craney Herkon Storker',
    achievementCraneyStorkerDetails: 'Obtain {{goal}} observations of Cranes, Storks and Herons.',
    achievementTooManyBugsTitle: 'So Many Bugs',
    achievementTooManyBugsDetails: 'Obtain {{goal}} observations of true Bugs.',
    achievementNotABugTitle: 'Not A Bug',
    achievementNotABugDetails: 'Obtain {{goal}} observations of species commonly thought to be "bugs", but aren\'t: Myriapods, Arachnids, Entognathans and Insects which are not true Bugs.',
    achievementLichenMossTitle: 'I Lichen Moss',
    achievementLichenMossDetails: 'Obtain {{goal}} observations of Lichens and Mosses.',
    achievementToadsAndToadstoolsTitle: 'Toads and Toadstools',
    achievementToadsAndToadstoolsDetails: 'Obtain {{goal}} observations of either Toads (and Frogs) or Mushrooms.',
    achievementFlowerChildTitle: 'Flower Child',
    achievementFlowerChildDetails: 'Make observations of {{goal}} different orders of Flowering Plants.',
    achievementWorldClassTitle: 'Classy Observer',
    achievementWorldClassDetails: 'Make observations in {{goal}} different taxonomic classes.',
    achievementDailyLifeTitle: 'Daily Life',
    achievementDailyLifeDetails: 'On {{goal}} days observe at least {{goal}} different species.',
    achievementAlwaysOnTitle: 'Always On',
    achievementAlwaysOnDetails: 'Make {{goal}} observations over a 5 day period.',
    achievementCatchOfTheDayTitle: 'Catch Of The Day',
    achievementCatchOfTheDayDetails: 'Observe {{goal}} Fish (or Shark) over a {{goal}} day period, with at least one observation on each consecutive day.',
    achievementEarlyBirdTitle: 'Early Bird',
    achievementEarlyBirdDetails: 'Observe both a Bird and a Segmented Worm before {{goal}}AM on {{goal}} different days.',
    achievementTentacleSuckersTitle: 'Tentacle Suckers',
    achievementTentacleSuckersDetails: 'Observe {{goal}} species of animals with tentacles (Octopus, Squid, Jellyfish and Sea Anemones).',
    achievementHomelyHermitTitle: 'Homely Hermit',
    achievementHomelyHermitDetails: 'Make {{goal}} observations over 7 days of any Hermit Crabs, Tortoises, Turtles, Snails or Bagworm Moths.',
    achievementMicrocosmTitle: 'My Microcosm',
    achievementMicrocosmDetails: 'Make {{goal}} observations of Bacteria, Diatoms, Rotifers, Tardigrades, Archaea, Protozoans, Nematodes and Viruses.',
    achievementEmployeeOfTheMonthTitle: 'Employee Of The Month',
    achievementEmployeeOfTheMonthDetails: 'Make {{goal}} observations of Ants, Termites or Honey Bees in one month.',
    achievementStriderTitle: 'Strider',
    achievementStriderDetails: 'Make two observations on the same day that are at least {{goal}}km apart.',
    achievementForeverSpringTitle: 'Forever Spring',
    achievementForeverSpringDetails: 'Observe a Flowering Plant that is annotated as "flowering" on each week of the year (calculated as {{goal}} periods of 7 days each, starting from January 1st).',
    achievementNotSeeingIsBelievingTitle: 'Not Seeing Is Believing',
    achievementNotSeeingIsBelievingDetails: 'Make {{goal}} observations that are annotated with "Evidence of Presence" other than "organism".',
    achievementPlentyOfPlacesTitle: 'Plenty Of Places',
    achievementPlentyOfPlacesDetails: 'Make observations in {{goal}} distinct 0.5 degree grids.',
    achievementAllCornersTitle: 'All Corners',
    achievementAllCornersDetails: 'Make an observation in each corner of the globe (North-West, North-East, South-East and South-West).',
    achievementCatsAndDogsTitle: 'Cats and Dogs',
    achievementCatsAndDogsDetails: 'Obtain observations of both Feline and Canine species on the same day, {{goal}} times.',
    achievementSuperStarTitle: 'Super Star',
    achievementSuperStarDetails: 'Observe {{goal}} species of Sea Star or Brittle Star.',
    achievementOldGeeserTitle: 'Old Geeser',
    achievementOldGeeserDetails: 'Obtain an observations of a Goose, Duck or Swan from {{goal}} years ago.',
    achievementScatterTitle: 'Scatter',
    achievementScatterDetails: 'Make {{goal}} observation, identified to species level, that have been annotated as "scat", and have been observed in {{goal}} different 0.25 degree grids.',
}

export const af = {
    iNaturalist: 'iNaturalist',
    iNaturalistWeb: 'iNaturalist.org',
    iNaturalistAPI: 'iNaturalist API',
    myWild: 'MyWild',
    headerTitle: 'Wilde Prestasies',
    headerIntro: 'Die doel van Wilde Prestasies is om jou iNaturalist ervaring te verbeter. Jou iNaturalist observasies word ge-analiseer en getel om the pret prestasies te valtooi.',
    headerIntroINatStart: 'Laai meet observasies op by ',
    headerIntroINatEnd: ' om meer prestasies te voltooi!',
    headerCalculate: 'Bereken Prestasies',
    headerLoading: 'Laai Prestasies',
    headerInput: 'iNaturalist Gebruikersnaam',
    version: 'Weergawe: {{version}} | Laas Opdateer: {{timestamp}} | Kode: {{commit}}',
    menuPopupParamsTitle: 'Beskikbare Adres Parameters',
    menuPopupParamsUser: 'Laai die prestasies vir die gegewe gebruker onmiddelik.',
    menuPopupParamsLimit: 'Die maksimum aantal observasies wat vanaf iNaturalist aangevra moet word.',
    menuPopupParamsExample: 'Voorbeeld:',
    menuPopupAboutTitle: 'Meer oor Wilde Prestasies',
    menuPopupAboutMyWildHeading: 'Ontwikkel deur MyWild (Henry de Lange).',
    menuPopupAboutMyWildTextStart: 'Jy is welkom om \'n taak te skep op GitHub of vir my \'n epos te stuur by ',
    menuPopupAboutMyWildTextEnd: ' met enige terugvoer.',
    menuPopupAboutMyWildDisclaimer: 'Let daarop dat hierdie nie \'n amptelikke iNaturalist webwerf is nie.',
    menuPopupAboutINaturalistTextStart: 'Hierdie webwerf word aangevuur deur die ',
    menuPopupAboutINaturalistTextEnd: '.',
    menuPopupRulesTitle: 'Hoe werk dit?',
    menuPopupRules: 'Observasies van organismes in gevangeskap of on-verifieerbare is word uitgesluit.Die webblad gebruik die iNaturalist API en probeer by die gebruiks ooreenkoms hou. Die data van gebruikers met baie observasies mag dus lank neem om af te laai.',
    menuButtonAbout: 'Omtrent',
    menuButtonRules: 'Reëls',
    menuButtonParams: 'Adres',
    menuButtonGitHub: 'GitHub',
    menuButtonDesktopApp: 'Toep',
    menuButtonDarkTheme: 'Donker Tema',
    menuButtonLightTheme: 'Ligte Tema',
    menuButtonSave: 'Stoor as \'n Prent',
    achievementTotal: 'Jy het {{count}} prestasies voltooi!',
    cardCountOfGoal: '{{count}} van {{goal}}',
    cardCompletedPercentage: 'Voltooi: {{percentage}}%',
    cardMysteryTitle: 'Dis \' misterie...',
    cardMysteryDetails: 'Hoe aan om observasies te maak om meer van hierdie prestasie te leer!',
    cardLegend: 'Legende',
    cardMaster: 'Meester',
    cardExpert: 'Ekspert',
    cardProficient: 'Voldoende',
    cardCompetent: 'Bevoegd',
    cardNovice: 'Beginner',
    cardCasual: 'Ontspanne',
    cardStarted: 'Begin',
    cardViewObservations: 'Sien Observasies:',
    cardNoObservations: 'Geen Observasies',
    progressUnknown: 'onbekend',
    progressSleep: 'Gee die iNaturalist bedieners \'n kort ruskans...',
    progressFetching: 'Laai die volgende {{per_page}} observasies vanaf iNaturalist... ({{count}} van {{total}} reeds gelaai)',
    progressPreparing: 'Additionele takson verwante data word vanaf iNaturalist gelaai.',
    progressCalculating: 'Bereken die Wilde Prestasies van die {{per_page}} nuwe observasies...',
    progressDone: 'Die Wilde Prestasies vir {{user}} is bereken, die {{count}} mees onlangse observasies vanaf iNaturalist was gebruik!',
    achievementLifeListerTitle: 'Lewe Lysser',
    achievementLifeListerDetails: 'Kry {{goal}} observasies met verskillende spesies vlak (of laer) identifikasies in een jaar.',
    achievementSelfPollinatorTitle: 'Self Bestuiwer',
    achievementSelfPollinatorDetails: 'Sit kommentaar op {{goal}} van you eie observasies.',
    achievementTryMammalsTitle: 'Soogdier Soeker',
    achievementTryMammalsDetails: 'Kry {{goal}} observasies van vlieënde, swemende en grouende soogdiere (minstens 3 vn elk).',
    achievementNightOwlTitle: 'Nag Uil',
    achievementNightOwlDetails: 'Kry {{goal}} Uil of Owl or Naguiltjie observasies in die aand (na 6PM eb voor 6AM).',
    achievementKingFisherTitle: 'Koning Visser',
    achievementKingFisherDetails: 'Kry {{goal}} Vis observasies.',
    achievementDaisyTownTitle: 'Madeliefie Tuin',
    achievementDaisyTownDetails: 'Kry {{goal}} Madeliefie observasies.',
    achievementHeartOfTheMatterTitle: 'Hart Van Die Saak',
    achievementHeartOfTheMatterDetails: 'Kry {{goal}} observasies van bedrygde spesies.',
    achievementSocialButterflyTitle: 'Sosiale Skoenlapper',
    achievementSocialButterflyDetails: 'Kry {{goal}} observasies met kommentaar van Skoenlappers en Motte.',
    achievementAirLoversTitle: 'Lug Liefhebbers',
    achievementAirLoversDetails: 'Kry {{goal}} observasies van Wind Swaels, Oewerswaels, Albatrosse en Aasvoëls.',
    achievementNameGiverTitle: 'Naamgewer',
    achievementNameGiverDetails: 'Maak {{goal}} identificasies op observasies.',
    achievementRatKingTitle: 'Rot Koning',
    achievementRatKingDetails: 'Kry {{goal}} observasies van Knaagdiere.',
    achievementCraneyStorkerTitle: 'Kraanige Reie Babavanger',
    achievementCraneyStorkerDetails: 'Kry {{goal}} observasies van Kraanvoëls, Ooievaars en Reiers.',
    achievementTooManyBugsTitle: 'So Baie Goggas',
    achievementTooManyBugsDetails: 'Kry {{goal}} observasies van Ware Goggas (Heteroptera).',
    achievementNotABugTitle: 'Nie \'n Gogga Nie',
    achievementNotABugDetails: 'Kry {{goal}} observasies van spesies wat algemeen as "goggas" gesien word, maar nie is nie: Myriapods, Arachnids, Entognathans en Insekte wat nie Ware Goggas is nie.',
    achievementLichenMossTitle: 'Lig Geen Mos',
    achievementLichenMossDetails: 'Kry {{goal}} observasies van Ligene en Mosse.',
    achievementToadsAndToadstoolsTitle: 'Paddas en Paddestoele',
    achievementToadsAndToadstoolsDetails: 'Kry {{goal}} observasies van of Paddas of Paddastoelle.',
    achievementFlowerChildTitle: 'Blomme Kind',
    achievementFlowerChildDetails: 'Maak observasies van {{goal}} verskillende ordes van Blom Plante.',
    achievementWorldClassTitle: 'Eerste Klas',
    achievementWorldClassDetails: 'Maak observasies in {{goal}} verskillende taksonomiese klasse.',
    achievementDailyLifeTitle: 'Daaglikse Lewe',
    achievementDailyLifeDetails: 'Op {{goal}} dae kry minstens {{goal}} verskillende spesies.',
    achievementAlwaysOnTitle: 'Altyd Aan',
    achievementAlwaysOnDetails: 'Maak {{goal}} observasies oor \'n 5 dag periode.',
    achievementCatchOfTheDayTitle: 'Vangs Van Die Dag',
    achievementCatchOfTheDayDetails: 'Maak {{goal}} Vis (of Haai) observasies oor \'n {{goal}} dag periode, met minstens een observasie op opeenvolgende dae.',
    achievementEarlyBirdTitle: 'Vroeë Voël',
    achievementEarlyBirdDetails: 'Kry beide \'n Voêl en Gesegmenteerde Wurm observasie voor {{goal}}AM op {{goal}} verskillende dae.',
    achievementTentacleSuckersTitle: 'Tentakels En Suiers',
    achievementTentacleSuckersDetails: 'Kry {{goal}} spesies van diere met tentakels (Seekatte, Inkvisse, Jellieisse en See Anemone).',
    achievementHomelyHermitTitle: 'Knus Kluisenaar',
    achievementHomelyHermitDetails: 'Maak {{goal}} observasies oor 7 dae van enige Kluisenaarskrappe, Skilpaaie, Slakke of Sakwurm Motte.',
    achievementMicrocosmTitle: 'Mikrokosmos',
    achievementMicrocosmDetails: 'Maak {{goal}} observasies van Bakterië, Diatome en eensellige organismes.',
    achievementEmployeeOfTheMonthTitle: 'Werker Van Die Maand',
    achievementEmployeeOfTheMonthDetails: 'Maak {{goal}} observasies van Miere, Termiete or Heuningbye in een maand.',
    achievementStriderTitle: 'Loper',
    achievementStriderDetails: 'Maak twee onbservasies op dieselfde dag wat minstens {{goal}}km van mekaar af is.',
    achievementForeverSpringTitle: 'Ewige Lente',
    achievementForeverSpringDetails: 'Maak observasies van Blom Plante wat geannoteer is as "blommend" in elke week van die jaar (bereken as {{goal}} periodes van 7 dae elk wat begin op die 1ste Januarie).',
    achievementNotSeeingIsBelievingTitle: 'Nie Sien Nie Is Glo',
    achievementNotSeeingIsBelievingDetails: 'Maak {{goal}} observasies met annotasies onder "Bewyse van Teenwoordigheid" wat nie "organisme" is nie.',
    achievementPlentyOfPlacesTitle: 'Baie Plekke',
    achievementPlentyOfPlacesDetails: 'Maak observasies in {{goal}} verskillende 0.5 grade blokke.',
    achievementAllCornersTitle: 'Alle Hoeke',
    achievementAllCornersDetails: 'Maak \'n observasie in elke hoek van die wêreld.',
    achievementCatsAndDogsTitle: 'Katte en Honde',
    achievementCatsAndDogsDetails: 'Kry observasies van beide Kat Familie en Hond Familie species op dieselfde dag, {{goal}} keer.',
    achievementSuperStarTitle: 'Super Ster',
    achievementSuperStarDetails: 'Kry {{goal}} Seester of Brosster spesies.',
    achievementOldGeeserTitle: 'Ou Gaans',
    achievementOldGeeserDetails: 'Kry \'n observatsie van \'n Gaans, Eend of Swaan van minstens {{goal}} jaar gelede.',
    achievementScatterTitle: 'Misser',
    achievementScatterDetails: 'Maak {{goal}} observsies, geidentifiseer na spesies vlak, wat geannoteer is as "mis", en oor {{goal}} verskillende 0.25 grade blokke waargeneem is.',
}